#conta {
  text-align: center;
  padding: 2em 2em 4em;
  #header {
    display: flex;
    flex-direction: row;
    // padding: 1em 1em 1em 2em;
    justify-content: space-between;
    place-items: center;
    h4 {
      margin: 0;
    }
  }
  #planos {
    margin: 2em auto;
    max-width: 500px;
    h2 {
      margin: 0;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      td {
        padding: .5em;
        text-align: left;
      }
      button {
        width: 100%;
      }
      tr:not(:last-child) td {
        border-bottom: 1px solid var(--branco);
      }
    }
  }
  .plano {
    .valor {
      text-align: center;
      font-weight: bold;
    }
    .nome {
      font-weight: bold;
      font-size: 1.2em;
      text-align: left;
    }
  }
  #compra {
    #dados {
      border: 1px solid var(--branco);
      border-radius: .5em;
      width: 500px;
      max-width: 80vw;
      margin: auto;
      padding: 1em 1em .5em;
      button {
        margin: 1em .5em;
      }
      h3 {
        margin: 0;
      }
    }
  }
}

#loading_conta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}