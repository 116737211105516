#index {

  .page {
    min-height: 60vh;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 2em;
  }
  
  #planos {
    #planos {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 700px) {
        flex-direction: column;
        margin-bottom: 2em;
      }
    }
    .plano {
      border: 1px solid var(--branco);
      padding: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 1em;
      &.primario {
        background: var(--branco);
        color: var(--cinza);
        button {
          color: var(--branco);
          background: var(--cinza);
        }
      }
      h2 {
        margin: 0;
        text-align: center;
      }
      button {
        border: 1px solid currentColor;
        background: transparent;
        border-radius: .5em;
        padding: .5em;
        color: inherit;
        margin-top: 1em;
      }
      
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      
      ul li {
        padding: .5em 1em;
        text-align: center;
        &:not(:last-of-type) {
          border-bottom: 1px solid currentColor;
        }
      }
  
      &:not(:last-of-type) {
        @media (max-width: 700px) {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom: none;
          max-width: 70%;
        }
        @media (min-width: 701px) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right: none;
        }
      }
    }
  }
  
  #form {
    #seletor {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 2em;
    }
    span {
      padding: .5em 1em;
      margin: 0 .5em;
      border-radius: .5em;
      cursor: pointer;
      &.ativo {
        background: var(--branco);
        color: var(--cinza);
      }
    }
    .field {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: .5em;
      input {
        width: 100%;
        padding: .5em 1em;
      }
    }
    #botoes {
      flex-direction: column;
    }
    #erro {
      color: #f55;
    }
    button {
      margin: 1em;
    }
  }
}