@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  background: var(--cinza);
  color: var(--branco);
}

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

:root {
  --cinza: #001;
  --branco: #eef;
}

input, button, button.secundario:hover {
  border-radius: .5em;
  border: 1px solid transparent;
  color: var(--cinza);
  background: var(--branco);
}

button {
  padding: .75em 2em;
  font-weight: bold;
  cursor: pointer;
}
button.small {
  padding: .5em 1em;
}

button:hover, button.secundario {
  background: var(--cinza);
  color: var(--branco);
  border: 1px solid var(--branco);
}
button.secundario:not(.outlined) {
  border-color: transparent;
}